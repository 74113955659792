<script lang="ts" setup></script>

<template>
  <div>
    <div class="text-center text-lg md:text-2xl">ขออภัย! การเข้าถึงหน้าเว็บนี้ไม่ได้รับอนุญาต</div>
    <div class="text-center text-sm md:text-lg text-grey-4">เราขออภัยอย่างยิ่งในความไม่สะดวกที่เกิดขึ้น<br/>แต่หน้าเว็บนี้มีการจำกัดการเข้าถึงเฉพาะสำหรับผู้ใช้ที่ได้รับอนุญาตเท่านั้น</div>
    <v-alert
      type="info"
      color="grey-4"
      text="หากคุณเชื่อว่ามีความผิดพลาด หรือมีคำถามเกี่ยวกับการเข้าถึงหน้าเว็บนี้ กรุณาติดต่อทีมงานของเรา"
      variant="outlined"
      class="mt-2 text-sm"
    ></v-alert>
  </div>
</template>

<style scoped></style>
